<template>


  <!-- Start Page Title Area -->
  <div class="page-title-area page-title-area-smaller" v-if="loadingAny">
    <div class="container">
      <div class="page-title-content">
        <h2>Loading training</h2>
        <div><pulse-loader :loading="loadingAny" color="#E21F36" size="10px"></pulse-loader></div>
      </div>
    </div>
  </div>
  <div class="page-title-area page-title-area-smaller" v-if="!loadingAny">
    <div class="container">
      <div class="page-title-content">
        <h2>{{sectionCatItem.title}}</h2>
        <h4 class="active">Overview</h4>
      </div>
    </div>
  </div>
  <!-- End Page Title Area -->

  <div class="page-content training-overview-page" v-if="!loadingAny">

    <!-- opper grid - cc-c-c -->
    <div class="row">

      <!-- left - sessions -->
      <div class="col-md-6" v-if="sessions.length">
        <h6 class="mb-0 text-uppercase">Sessions</h6>
        <hr>

        <!-- session box -->
        <div class="card radius-10" v-for="session in sessions" v-bind:key="session.creationTime">
          <div class="card-body">

            <div class="row">
              <div class="col-sm-6">
                {{ this.utcToLocal(session.creationTime) }}
              </div>
              <div class="col-sm">
                {{session.questionsAnswered}} of {{ session.questionsAmount }}
              </div>
              <div class="col-sm">
                <router-link :to="linkTrainingSession(session.session)" class="opensession-btn" style="float: right;">open</router-link>

              </div>
            </div>

          </div>
        </div>

        <div class="card-body">

          <div class="create-new-session-row">
            <div class="create-new-session-row-text">
              continue here with new sessions!
              <i clas="fa-sharp fa-solid fa-person-walking-arrow-right"></i>
            </div>

            <div class="create-new-session-row-button">
              <button @click="createSession" class="opensession-btn" style="float: right;">create session</button>
            </div>
          </div>

        </div>



      </div>


      <!-- left - no sessions -->
      <div class="col-md-6" v-if="!sessions.length">
        <h6 class="mb-0 text-uppercase">Sessions</h6>
        <hr>

        <div class="card-body">

          <div class="row">
            <div class="col-sm-8 text-sm-end">
              Start with new session
              <i class="fa-sharp fa-solid fa-right"></i>
            </div>

            <div class="col-sm">
              <button @click="createSession" class="opensession-btn" style="float: right;">create your first session</button>
            </div>
          </div>

        </div>



      </div>


      <!-- right -->
        <div class="col-md-6" v-if="statData !== undefined && statData.answered && statData.answered > 5">
          <h6 class="mb-0 text-uppercase">Summary</h6>
          <hr>

          <div class="row">
            <div class="col-md-6" v-if="Object.keys(this.categoriesCurrentSkillApexRadar).length > 1">
              <ApexBoxRadar v-bind:dataNode="categoriesCurrentSkillApexRadar" title="Current section strength" color="#4B2E80"  class="apexGapBelow" />
            </div>

            <div class="col-md-6">
              <ApexBoxDonut v-if="Object.keys(this.completionTotalApexNode).length !== 0" v-bind:dataNode="completionTotalApexNode" title="Coverage" />
              <br/>

              <!-- box -->
              <div class="card radius-10 apexGapBelow">
                <div class="card-body card-body-statbox2 ">
                  <div class="d-flex align-items-center ">
                    <div>
                      <p class="mb-0 text-secondary">Correct / Answered</p>
                      <h4 class="my-1">{{statData.answersCorrect}} of {{statData.answered}}</h4>
                    </div>
                    <div class="text-primary ms-auto font-35">
                      <i class="fal fa-money-check-edit"></i>
                    </div>
                  </div>
                  <div class="cardDescText">any answer counted</div>
                </div>
              </div>

            </div>
          </div>



          <h6 class="mb-0 text-uppercase" id="titleComplexity">Complexity</h6>
          <hr>

          <div class="row" v-if="statData !== undefined">
            <div class="col-md-6" v-if="progressAvgTotal && progressAvgTotal.length > 2">
              <ApexBoxSpline v-bind:values="progressAvgTotal" title="Mixed skill gain" color="#6F9ABD" class="apexGapBelow" />
            </div>
            <div class="col-md-6" v-if="diffScoresForLevel1 && diffScoresForLevel1.length > 2">
              <ApexBoxSpline v-bind:values="diffScoresForLevel1" title="Level EASY skill gain" color="#0e6f05" class="apexGapBelow" />
            </div>
            <br/>
            <div class="col-md-6" v-if="diffScoresForLevel2 && diffScoresForLevel2.length > 2">
              <ApexBoxSpline v-bind:values="diffScoresForLevel2" title="Level NORMAL skill gain" color="#D1A000" class="apexGapBelow" />
            </div>
            <div class="col-md-6" v-if="diffScoresForLevel3 && diffScoresForLevel3.length > 2">
              <ApexBoxSpline v-bind:values="diffScoresForLevel3" title="Level ADVANCED skill gain" color="#BD0000" class="apexGapBelow" />
            </div>
            <div class="col-md-6" v-if="diffScoresForLevel4 && diffScoresForLevel4.length > 2">
              <ApexBoxSpline v-bind:values="diffScoresForLevel4" title="Level HARD skill gain" color="#7100BD" class="apexGapBelow" />
            </div>

          </div>


          <h6 class="mb-0 text-uppercase">Progress per section</h6>
          <hr>

          <div class="row" v-if="statData !== undefined">
            <div v-for="(data,section) in statData.progressAvgPerSection" v-bind:key="section" class="col-md-6">
              <ApexBoxSpline v-bind:values="data" v-bind:title="sectionsTitleById[section]" color="#3F579E" class="apexGapBelow" v-if="data.length > 0"/>
            </div>

          </div>


        </div>


        <div class="col-md-6" v-if="statData === undefined || statData.answered === undefined || statData.answered <= 5">
          <h6 class="mb-0 text-uppercase">Statistic</h6>
          <hr>
          <p>Answer more questions to display statics by creating a training session.</p>
        </div>

      </div>


    <!-- end boxes -->

  </div>




</template>


<script>
setTimeout(myFunction, 2000); // to check apex display error and resize/refresh window
setTimeout(myFunction, 5000); // again
setTimeout(myFunction, 10000); // again

function myFunction() {
  const element = document.getElementById("titleComplexity");


  if (getOffset(element).top > 1000){
    console.log("Element of titleComplexity seems to be to low, looks like apex graphics error. Refreshing page ...")
    window.dispatchEvent(new Event('resize'));
  }
}

function getOffset(el) {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY
  };
}


import  { initAuthUser, changeOnAuthEvent } from '@/js/authutil'
import  { utcStringToLocal } from '@/js/datecalc'
import {Auth} from "aws-amplify";
import {AuthState} from "@aws-amplify/ui-components";
import axios from "axios";
import ApexBoxSpline from '@/views/fragments/ApexBoxSpline'
import ApexBoxDonut from '@/views/fragments/ApexBoxDonut'
import ApexBoxRadar from '@/views/fragments/ApexBoxRadar'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {

  components: {
    ApexBoxSpline,
    ApexBoxDonut,
    ApexBoxRadar,
    PulseLoader
  },

  data() {
    return {
      sectionCatItem: {},
      statData: {},
      sessions: [],
      sectionsTitleById: {},
      completionTotalApexNode: {},
      categoriesCurrentSkillApexRadar: {"data":[],"labels":[]},

      loadingSubjects: true,
      loadingStats: true,
      loadingSessions: true
     }
  },


  created() {
    console.log('created()');

    // but also check if already signed in
    if (this.userSignedIn){
      this.loadData()
    }else{
      initAuthUser(this, Auth, AuthState)
      changeOnAuthEvent(this, this.onAuthComponentProcessed) // INIT process on change (late login)
    }

    window.scrollTo(0,0); // scroll to top
  },

  updated(){
    console.log('updated()')

    // this is a workaround as apexcharts appears sometimes twice
    // https://github.com/apexcharts/vue3-apexcharts/issues/3
    this.$nextTick(() => {
      window.dispatchEvent(new Event('resize'));
    });

  },


  computed: {


    loadingAny() {
      if (this.loadingSubjects || this.loadingStats || this.loadingSessions) {
        return true
      } else {
        return false
      }
    },

    userSignedIn(){
      return this.$store.getters.userSignedIn
    },

    progressAvgTotal(){
      let list = []
      try{
        list = this.statData.progressAvgTotal
      }catch(error){
        // ignore me
      }
      return list
    },

    diffScoresForLevel1(){
      let list = []
      try{
        list = this.statData.progressAvgPerDifficulty[1]
        console.log("Skill gain easy")
        console.log(list.join(","))
      }catch(error){
        // ignore me
      }
      return list
    },

    diffScoresForLevel2(){
      let list = []
      try{
        list = this.statData.progressAvgPerDifficulty[2]
        console.log("Skill gain normal")
        console.log(list.join(","))
      }catch(error){
        // ignore me
      }
      return list
    },

    diffScoresForLevel3(){
      let list = []
      try{
        list = this.statData.progressAvgPerDifficulty[3]
        console.log("Skill gain advanced")
        console.log(list.join(","))
      }catch(error){
        // ignore me
      }
      return list
    },
    diffScoresForLevel4(){
      let list = []
      try{
        list = this.statData.progressAvgPerDifficulty[4]
        console.log("Skill gain hard")
        console.log(list.join(","))
      }catch(error){
        // ignore me
      }
      return list
    }



  },


  methods: {

    // loads the content or redirectsif user is not signedin.
    // Must be done AFTER amplify-authenticator has been loaded
    // and updated to know if user is signedin or not.
    onAuthComponentProcessed: function(){
      if ( this.$route.name !== 'Training'){return} // skip if page does not fit, as it keeps this function in Auth globally

      if (! this.userSignedIn){
        console.log('user not signed in, not allowed to be on this page - redirect!')
        this.$router.push('/login')
      }else{
        this.loadData()
      }

    },


    loadData: function() {
      axios.defaults.headers = {
        'Content-Type': 'application/json',
        'Authorization': this.$store.getters.userAccessToken
      }



      console.log('going to load training data ...')

      if (this.loadDone === true){return} // skip if already done
      this.loadDone = true

      this.sectionCat = this.$route.params.sectionCat
      console.log("Section cat opened: %s", this.sectionCat)

      // load training meta information
      const url_cat = process.env.VUE_APP_URL_GET_SUBJECTDETAILS+'/'+this.sectionCat
      console.log("GET: "+url_cat)
      axios.get(url_cat, { cache: true }).then(

          res=> {
            this.sectionCatItem=res.data.payload.item

            this.sectionCatItem.sections.forEach( node => {
              this.sectionsTitleById[node.id] = node.title
            })

            this.loadingSubjects = false
          }
      ).catch(error => console.log('GET ERROR: ',error));

      // load stats
      const url_stat = process.env.VUE_APP_URL_GET_TRAINING_STATS+'/'+this.sectionCat
      console.log("GET: "+url_stat)

      axios.get(url_stat, { cache: true }).then(
          res=> {
            console.log("training stats received from server")
            this.statData=res.data.payload.item
            this.completionAsApexArray(this.completionTotalApexNode, this.statData.completionTotal)
            this.updateCategoriesCurrentSkillRadar()
            this.loadingStats = false
          }
      ).catch(error => console.log('GET ERROR: ',error));

      // load sessions
      const url_ses = process.env.VUE_APP_URL_GET_TRAINING_SESSIONS+'/'+this.sectionCat
      console.log("GET: "+url_ses)
      axios.get(url_ses, { cache: true }).then(
          res=> {
            this.sessions=res.data.payload.records
            this.loadingSessions = false
          }
      ).catch(error => console.log('GET ERROR: ',error));
    },

    createSession: function(){
      console.log('create session button clicked, redirecting ...')
      const url = '/training_create_session/'+this.sectionCat
      this.$router.push(url)
    },

    utcToLocal: function(utcString) {
      return utcStringToLocal(utcString)
    },

    linkTrainingSession(sessionId){
      return '/training_session/'+sessionId
    },

    completionAsApexArray(target, node){
      const series = []
      series.push(node['correct'])
      series.push(node['wrong'])
      series.push(node['untapped'])
      const labels = ['correct', 'wrong', 'untapped']
      const colors = ['#0e6f05', '#bd0000', '#909090']
      target['series'] = series
      target['colors'] = colors
      target['labels'] = labels
    },

    updateCategoriesCurrentSkillRadar(){
      this.categoriesCurrentSkillApexRadar['data'] = []
      this.categoriesCurrentSkillApexRadar['labels'] = []

      for (const [key, entries] of Object.entries(this.statData.progressAvgPerSection)) {
        const lastEntry = entries[entries.length - 1]
        if (lastEntry !== undefined){
          let label = this.sectionsTitleById[key]
          console.log("skill radar entry: %s [%s] - %d", key, label, lastEntry)
          this.categoriesCurrentSkillApexRadar['labels'].push(label)
          this.categoriesCurrentSkillApexRadar['data'].push(lastEntry)
        }
      }

      console.log("categoriesCurrentSkillApexRadar: ", this.categoriesCurrentSkillApexRadar)
    }

  }


};



</script>